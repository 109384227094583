import request from '@/utils/request'

// 查询
export function listRecruitmentPage(data) {
  return request({
    url: '/system/recruitment/listRecruitmentPage',
    method: 'post',
    data
  })
}

// 单个查询
export function selectBYid(data) {
  return request({
    url: '/system/recruitment/selectBYid',
    method: 'get',
    params:data
  })
}

// 新增
export function insertRecruitment(data) {
    return request({
      url: '/system/recruitment/insertRecruitment',
      method: 'post',
      data
    })
  }

// 修改
export function updateRecruitment(data) {
    return request({
      url: '/system/recruitment/updateRecruitment',
      method: 'post',
      data
    })
  }

// 删除
export function deleteRecruitment(data) {
    return request({
      url: '/system/recruitment/deleteRecruitment',
      method: 'post',
      data
    })
  }

// 删除
export function importExcel(data) {
  return request({
    url: '/system/recruitment/importExcel',
    method: 'post',
    data
  })
}

// 招聘标签-新增
export function insertRecruitmentLabel(data) {
  return request({
    url: '/system/recruitmentLabel/insertRecruitmentLabel',
    method: 'post',
    data
  })
}

// 编辑招聘标签
export function updateRecruitmentLabel(data) {
  return request({
    url: '/system/recruitmentLabel/updateRecruitmentLabel',
    method: 'post',
    data
  })
}

// 招聘标签信息
export function selectRecruitmentLabelDetail(data) {
  return request({
    url: '/system/recruitmentLabel/selectRecruitmentLabelDetail',
    method: 'get',
    params:data
  })
}

// 手动打标签新增
export function insertRecruitmentLabelManual(data) {
  return request({
    url: '/system/recruitmentLabel/insertRecruitmentLabelManual',
    method: 'post',
    data
  })
}

// 手动打标签编辑更新
export function updateRecruitmentLabelManual(data) {
  return request({
    url: '/system/recruitmentLabel/updateRecruitmentLabelManual',
    method: 'post',
    data
  })
}

// 查询当前产品所有的标签
export function getById(data) {
  return request({
    url: '/system/recruitmentLabel/getById',
    method: 'get',
    params:data
  })
}

// 半自动化新增
export function insertAndUpdateRecruitmentMatchedLabel(data) {
  return request({
    url: '/system/label/insertAndUpdateRecruitmentMatchedLabel',
    method: 'post',
    data
  })
}

// 通过ID查询历史匹配标签--招聘
export function findHistoryMatchedByRecruitment(data) {
  return request({
    url: '/system/label/findHistoryMatchedByRecruitment',
    method: 'get',
    params:data
  })
}
