<template>
  <div class="addenterprise" ref="addenterprise">
    <div
      class="essential_information"
      v-loading="loading"
      element-loading-text="正正加载标签,请稍后..."
    >
      <div class="form_essential">
        <div class="form_essential_search">
          <div class="fixed" ref="fixed">
            <div class="title">标签组列表</div>
            <div class="search_input">
              <el-form>
                <el-form-item>
                  <el-input
                    v-model="groupLabelName"
                    suffix-icon="el-icon-search"
                    placeholder="请输入标签组名称"
                    @input="selectLikeGroupLabelName"
                  ></el-input> </el-form-item
              ></el-form>
            </div>
            <div class="list">
              <ul>
                <li
                  v-for="(item, index) in allLabelGroup"
                  :key="index + 'type'"
                >
                  <a :href="`#${item.groupLabelType}`" style="color: #333">{{
                    item.groupLabelType
                  }}</a>
                  <span> ({{ item.labelCount }}) </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="form_essential_left">
          <el-form ref="form" :model="form">
            <div
              style="padding-top: 30px"
              v-for="(item, index) in radiolist"
              :key="index"
            >
              <el-form-item :label="item.val" prop="">
                <!-- 绑定 labelparam1 -->
                <el-checkbox-group v-model="item.labelparam1">
                  <!-- 显示 typelist -->
                  <el-checkbox
                    v-for="o in item.typelist"
                    :label="o.labelName"
                    :key="o.id"
                    @change="codechange1(o)"
                  >
                    <!-- 锚点 -->
                    <span :id="o.groupLabelType">{{ o.labelName }}</span>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- 显示 codechildlist1 -->
                <div class="child_label" v-if="item.codechildlist1.length > 0">
                  <span
                    style="color: #7c7f8e; width: 65px; display: inline-block"
                    >二级标签:</span
                  >
                  <!-- 绑定 labelparam2 -->
                  <el-checkbox-group v-model="item.labelparam2">
                    <el-checkbox
                      v-for="i in item.codechildlist1"
                      :label="i.labelName"
                      :key="i.id"
                      @change="codechangechild(i)"
                      >{{ i.labelName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div
                  style="margin-top: 15px"
                  class="child_label"
                  v-if="item.childlist.length > 0"
                >
                  <span
                    style="color: #7c7f8e; width: 65px; display: inline-block"
                    >三级标签:</span
                  >
                  <el-checkbox-group v-model="item.labelparam3">
                    <el-checkbox
                      v-for="el in item.childlist"
                      :label="el.labelName"
                      :key="el.id"
                      @change="codechangechil4(el)"
                      >{{ el.labelName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div
                  style="margin-top: 15px"
                  class="child_label"
                  v-if="item.childlist4.length > 0"
                >
                  <span
                    style="color: #7c7f8e; width: 65px; display: inline-block"
                    >四级标签:</span
                  >
                  <el-checkbox-group v-model="item.labelparam4">
                    <el-checkbox
                      v-for="els in item.childlist4"
                      :label="els.labelName"
                      :key="els.id"
                      @change="codechangechil5(els)"
                      >{{ els.labelName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div
                  style="margin-top: 15px"
                  class="child_label"
                  v-if="item.childcode5.length > 0"
                >
                  <span
                    style="color: #7c7f8e; width: 65px; display: inline-block"
                    >五级标签:</span
                  >
                  <el-checkbox-group v-model="item.labelparam5">
                    <el-checkbox
                      v-for="item in item.childcode5"
                      :label="item.labelName"
                      :key="item.id"
                      @change="paramchange5(item)"
                      >{{ item.labelName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div
                  style="margin-top: 15px"
                  class="child_label"
                  v-if="item.childcode6.length > 0"
                >
                  <span
                    style="color: #7c7f8e; width: 65px; display: inline-block"
                    >六级标签:</span
                  >
                  <el-checkbox-group v-model="item.labelparam6">
                    <el-checkbox
                      v-for="item in item.childcode6"
                      :label="item.labelName"
                      :key="item.id"
                      @change="paramchange6(item)"
                      >{{ item.labelName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div class="form_essential_right">
          <div class="fixedRight" ref="fixedRight">
            <h4>已选标签</h4>
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-for="(item, index) in selectedlist"
                :key="index"
                :name="item.id"
              >
                <template slot="title">
                  <span>{{ item.val }}({{ item.tags.length }})</span>
                </template>
                <div class="tags">
                  <div class="tags_context">
                    <el-tag
                      @close="handleClose(tag, item, indexs)"
                      v-for="(tag, indexs) in item.tags"
                      :key="indexs"
                      closable
                    >
                      {{ tag.labelName }}
                    </el-tag>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="margin-top: 30px" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()">保存</el-button>
      <el-button @click="cancls">取消</el-button>
    </div> -->
  </div>
</template>

<script>
// 查询所有标签组 / 模糊查询标签组 / 查询当前企业所有的标签
import {
  selectAllLabelGroup,
  selectLikeGroupLabelName,
} from "@/api/caseData.js";
import { getLabel, getChild } from "@/api/content.js";
// 查询
// import { getById } from "@/api/policy.js";
import { getById } from "@/api/recruit.js";

// import {
//   savelabel,
//   getBylabelId,
//   updateInformationLabel,
// } from "@/api/datement.js";
export default {
  name: "addenterprise",
  computed: {
    // id() {
    //   return this.$route.query.id;
    // },
  },
  props: {
    // 数据新增唯一id
    addId: {
      type: String,
    },
    // 数据编辑唯一id
    id: {
      type: Number,
    },
    isload:{
      type: Boolean,
    }
  },
  data() {
    return {
      form: {},
      loading: false,
      //标签类别
      radiolist: [],
      codelist: [], //储存全部标签
      codelist1: [], //父级标签
      codechildlist1: [], //二级
      childcode1: [], //三级
      childcode4: [], //四级
      childcode5: [], //五级
      childcode6: [], //六级
      //储存回显数据
      labeList: [],
      childResults: [],
      selectedlist: [],
      activeNames: [],
      allLabelGroup: [], //所有标签组
      groupLabelName: "", //查询标签需要带的参数
      scrollTop: 0,
    };
  },
  created() {
    this.getLabel();
    this.selectAllLabelGroup();
  },
  // beforeUpdate() {
  //   this.$refs.fixed.style.top = "156px";
  //   this.$refs.fixedRight.style.top = "156px";
  //   this.$refs.fixed.style.transition = "all 0.4s";
  //   this.$refs.fixedRight.style.transition = "all 0.4s";
  // },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
    // 需要滚动
    document.getElementsByClassName("el-tabs__content")[0].style.height =
    document.body.clientHeight - 388 + "px";
  },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    // handleScroll() {
    //   this.scrollTop = this.$refs.addenterprise.getBoundingClientRect().top;
    //   if (this.scrollTop <= 56) {
    //     this.$refs.fixed.style.top = "70px";
    //     this.$refs.fixed.style.transition = "all 0.4s";
    //     // this.$refs.fixed.style.height = 0;
    //     this.$refs.fixedRight.style.top = "70px";
    //     this.$refs.fixedRight.style.transition = "all 0.4s";
    //     // this.$refs.fixedRight.style.height = calc("100vh" - "60px");
    //   } else {
    //     this.$refs.fixed.style.top = "156px";
    //     this.$refs.fixedRight.style.top = "156px";
    //   }
    // },
    //回显标签
    async getBylabelId() {
      // 如果这个请求回来是0的话,说明还没新增过数据,就调用新增的接口,如果查询回来有数据,就是新增过,就调用编辑的接口
      const res = await getById({
        recruitmentId: this.addId ? this.addId : this.id,
      });
      if (res.code == 200) {
        this.labeList = [];
        // 储存回显的数据,根据这个值来判断是新增接口还是编辑接口
        this.labeList = res.data;
        // 遍历查出来的数组
        this.labeList.forEach((o) => {
          // 遍历总的数组
          this.radiolist.forEach((el) => {
            // 如果查出来的labelType名字等等于处理后总数组的名字的话
            if (o.labelType == el.id) {
              el.labelparam.labelNameList = o.labelNameList;

              o.labelNameList.forEach((i) => {
                el.typelist.forEach((s) => {
                  if (i.labelName == s.labelName) {
                    //一二级回显
                    s.checked = true;
                    el.labelparam1.push(s.labelName);
                    el.labelparam1 = [...new Set(el.labelparam1)];

                    //右边回显
                    this.selectedlist.forEach((p) => {
                      if (p.id == o.labelType) {
                        p.tags.push(s);
                        p.tags = this.removal(p.tags);
                      }
                    });
                    getChild({ parentId: s.id }).then((response) => {
                      this.childResults = response.data;

                      el.codechildlist1 = el.codechildlist1.concat(
                        this.childResults
                      );
                      el.codechildlist1 = this.removal(el.codechildlist1);

                      //三四级回显
                      if (el.codechildlist1.length > 0) {
                        o.labelNameList.forEach((i) => {
                          el.codechildlist1.forEach((s) => {
                            if (i.labelName == s.labelName) {
                              s.checked = true;

                              el.labelparam2.push(s.labelName);
                              el.labelparam2 = [...new Set(el.labelparam2)];

                              //左边回显
                              if (s.checked) {
                                this.selectedlist.forEach((p) => {
                                  if (p.id == o.labelType) {
                                    p.tags.push(s);
                                    p.tags = this.removal(p.tags);
                                  }
                                });
                              }
                              getChild({ parentId: s.id }).then((response) => {
                                this.childResults = response.data;
                                el.childlist = el.childlist.concat(
                                  this.childResults
                                );
                                el.childlist = this.removal(el.childlist);

                                if (el.childlist.length > 0) {
                                  o.labelNameList.forEach((i) => {
                                    el.childlist.forEach((s) => {
                                      if (i.labelName == s.labelName) {
                                        el.labelparam3 = [];

                                        //四五级回显
                                        s.checked = true;
                                        el.labelparam3.push(s.labelName);
                                        el.labelparam3 = [
                                          ...new Set(el.labelparam3),
                                        ];

                                        //左边回显
                                        if (s.checked) {
                                          this.selectedlist.forEach((p) => {
                                            if (p.id == o.labelType) {
                                              p.tags.push(s);
                                              p.tags = this.removal(p.tags);
                                            }
                                          });
                                        }
                                        getChild({ parentId: s.id }).then(
                                          (response) => {
                                            this.childResults = response.data;
                                            el.childlist4 =
                                              el.childlist4.concat(
                                                this.childResults
                                              );
                                            el.childlist4 = this.removal(
                                              el.childlist4
                                            );

                                            if (el.childlist4.length > 0) {
                                              o.labelNameList.forEach((i) => {
                                                el.childlist4.forEach((s) => {
                                                  if (
                                                    i.labelName == s.labelName
                                                  ) {
                                                    el.labelparam4 = [];

                                                    //四五级回显
                                                    s.checked = true;

                                                    el.labelparam4.push(
                                                      s.labelName
                                                    );
                                                    el.labelparam4 = [
                                                      ...new Set(
                                                        el.labelparam4
                                                      ),
                                                    ];

                                                    //左边回显
                                                    if (s.checked) {
                                                      this.selectedlist.forEach(
                                                        (p) => {
                                                          if (
                                                            p.id == o.labelType
                                                          ) {
                                                            p.tags.push(s);
                                                            p.tags =
                                                              this.removal(
                                                                p.tags
                                                              );
                                                          }
                                                        }
                                                      );
                                                    }
                                                    getChild({
                                                      parentId: s.id,
                                                    }).then((response) => {
                                                      this.childResults =
                                                        response.data;
                                                      el.childcode5 =
                                                        el.childcode5.concat(
                                                          this.childResults
                                                        );
                                                      el.childcode5 =
                                                        this.removal(
                                                          el.childcode5
                                                        );

                                                      if (
                                                        el.childcode5.length > 0
                                                      ) {
                                                        o.labelNameList.forEach(
                                                          (i) => {
                                                            el.childcode5.forEach(
                                                              (s) => {
                                                                if (
                                                                  i.labelName ==
                                                                  s.labelName
                                                                ) {
                                                                  el.labelparam5 =
                                                                    [];

                                                                  //四五级回显
                                                                  s.checked = true;

                                                                  el.labelparam5.push(
                                                                    s.labelName
                                                                  );
                                                                  el.labelparam5 =
                                                                    [
                                                                      ...new Set(
                                                                        el.labelparam5
                                                                      ),
                                                                    ];

                                                                  //左边回显
                                                                  if (
                                                                    s.checked
                                                                  ) {
                                                                    this.selectedlist.forEach(
                                                                      (p) => {
                                                                        if (
                                                                          p.id ==
                                                                          o.labelType
                                                                        ) {
                                                                          p.tags.push(
                                                                            s
                                                                          );
                                                                          p.tags =
                                                                            this.removal(
                                                                              p.tags
                                                                            );
                                                                        }
                                                                      }
                                                                    );
                                                                  }
                                                                  getChild({
                                                                    parentId:
                                                                      s.id,
                                                                  }).then(
                                                                    (
                                                                      response
                                                                    ) => {
                                                                      this.childResults =
                                                                        response.data;
                                                                      el.childcode6 =
                                                                        el.childcode6.concat(
                                                                          this
                                                                            .childResults
                                                                        );
                                                                      el.childcode6 =
                                                                        this.removal(
                                                                          el.childcode6
                                                                        );

                                                                      if (
                                                                        el
                                                                          .childcode6
                                                                          .length >
                                                                        0
                                                                      ) {
                                                                        o.labelNameList.forEach(
                                                                          (
                                                                            i
                                                                          ) => {
                                                                            el.childcode6.forEach(
                                                                              (
                                                                                s
                                                                              ) => {
                                                                                if (
                                                                                  i.labelName ==
                                                                                  s.labelName
                                                                                ) {
                                                                                  el.labelparam6 =
                                                                                    [];

                                                                                  //六·级回显
                                                                                  s.checked = true;

                                                                                  el.labelparam6.push(
                                                                                    s.labelName
                                                                                  );
                                                                                  el.labelparam6 =
                                                                                    [
                                                                                      ...new Set(
                                                                                        el.labelparam6
                                                                                      ),
                                                                                    ];
                                                                                  //左边回显
                                                                                  if (
                                                                                    s.checked
                                                                                  ) {
                                                                                    this.selectedlist.forEach(
                                                                                      (
                                                                                        p
                                                                                      ) => {
                                                                                        if (
                                                                                          p.id ==
                                                                                          o.labelType
                                                                                        ) {
                                                                                          p.tags.push(
                                                                                            s
                                                                                          );
                                                                                          p.tags =
                                                                                            this.removal(
                                                                                              p.tags
                                                                                            );
                                                                                        }
                                                                                      }
                                                                                    );
                                                                                  }
                                                                                }
                                                                              }
                                                                            );
                                                                          }
                                                                        );
                                                                      }
                                                                    }
                                                                  );
                                                                }
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    });
                                                  }
                                                });
                                              });
                                            }
                                          }
                                        );
                                      }
                                    });
                                  });
                                }
                              });
                            }
                          });
                        });
                      }
                    });
                  }
                });
              });
            }
          });
        });
      }
      this.labelmatic();
    },

    handleClose(tag, item, indexs) {
      let list = tag.id.split("-");

      if (list.length - 1 == 1) {
        console.log(tag, "tagarr传参");
        this.codechange1(tag);
        this.radiolist.forEach((el) => {
          el.labelparam1.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam1.splice(index, 1);
            }
          });
        });
      }
      if (list.length - 1 == 2) {
        this.codechangechild(tag);
        this.radiolist.forEach((el) => {
          el.labelparam2.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam2.splice(index, 1);
            }
          });
        });
      }
      if (list.length - 1 == 3) {
        this.codechangechil4(tag);
        this.radiolist.forEach((el) => {
          el.labelparam3.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam3.splice(index, 1);
            }
          });
        });
      }
      if (list.length - 1 == 4) {
        this.codechangechil5(tag);
        this.radiolist.forEach((el) => {
          el.labelparam4.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam4.splice(index, 1);
            }
          });
        });
      }
      if (list.length - 1 == 5) {
        this.paramchange5(tag);
        this.radiolist.forEach((el) => {
          el.labelparam5.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam5.splice(index, 1);
            }
          });
        });
      }
      if (list.length - 1 == 6) {
        this.radiolist.forEach((el) => {
          el.labelparam6.forEach((o, index) => {
            if (tag.labelName == o) {
              el.labelparam6.splice(index, 1);
            }
          });
        });
      }
      item.tags.splice(indexs, 1);
    },
    //企业类别
    async getLabel() {
      this.loading = true;
      const res = await getLabel({
        modules: '12'
      });
      if (res.code == 200) {
        this.codelist = res.data;

        this.codelist.forEach((el, index) => {
          this.radiolist.push({
            id: el.groupLabelType, //标签组名称
            val: el.groupLabelType + ":", //标签组名称：
            typelist: el.labelVoList, //一级标签
            codechildlist1: [],
            childlist: [],
            childlist4: [],
            childcode5: [],
            childcode6: [],
            labelparam1: [],
            labelparam2: [],
            labelparam3: [],
            labelparam4: [],
            labelparam5: [],
            labelparam6: [],
            labelparam: {
              recruitmentId: this.addId ? this.addId : this.id,
              labelNameList: [],
              labelType: el.groupLabelType, //标签组名称
            },
          });
          this.selectedlist.push({
            id: el.groupLabelType,
            val: el.groupLabelType,
            num: 0,
            tags: [],
          });
        });
        this.loading = false;
        if (this.id || this.addId) {
          this.getBylabelId();
        }
        // 怎么在这里调用
        // if (this.companyId) {
        // this.getBylabelId();
        // }
        // for (let i in this.codelist) {
        //   this.radiolist.forEach((item) => {

        //     if (item.id == i) {
        //       item.typelist = this.codelist[i]
        //       item.typelist.forEach((s) => {
        //         this.$set(s, 'checked', false)
        //       })

        //     }
        //   })
        // }
      } else {
        // this.$message.error(res.message);
      }
    },
    //查询父级下的子级标签 id=父级id o.checked=判断是添加子集还是删除  index=判断层级
    //查询父级下的子级标签 id=父级id o.checked=判断是添加子集还是删除  index=判断层级
    async getChild(id, o, index) {
      const res = await getChild({ parentId: id });
      if (res.code == 200) {
        this.childResults = res.data;
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.id) {
            if (o.checked) {
              if (index == 1) {
                item.codechildlist1 = item.codechildlist1.concat(
                  this.childResults
                );
              } else if (index == 2) {
                item.childlist = item.childlist.concat(this.childResults);
              } else if (index == 3) {
                item.childlist4 = item.childlist4.concat(this.childResults);
              } else if (index == 4) {
                item.childcode5 = item.childcode5.concat(this.childResults);
              } else {
                item.childcode6 = item.childcode6.concat(this.childResults);
              }
            } else {
              if (index == 1) {
                //取消右侧标签
                this.selectedlist.forEach((h) => {
                  if (h.id == item.id) {
                    let labelname = h.tags.filter(
                      (s) =>
                        !this.childResults.some(
                          (ele) => ele.labelName == s.labelName
                        )
                    );
                    h.tags = labelname;
                  }
                });
                //得到需要取消的二级跟现有的二级相同的子集
                let namelabellist = item.codechildlist1.filter((s) =>
                  this.childResults.some((ele) => ele.labelName == s.labelName)
                );
                if (this.childResults.length > 0) {
                  namelabellist.forEach((el) => {
                    // //取消勾选去除三级
                    if (el.checked) {
                      el.checked = !el.checked;
                      this.getChild(el.id, el, 2);
                    }
                  });
                }

                //取消勾选时去除相应二级
                let namelist = item.codechildlist1.filter(
                  (s) =>
                    !this.childResults.some(
                      (ele) => ele.labelName == s.labelName
                    )
                );

                //赋值去除需要取消的子集
                item.codechildlist1 = namelist;

                namelabellist.forEach((el) => {
                  //清楚请求参数里的已勾选子集
                  if (el.groupLabelType == item.labelparam.labelType) {
                    item.labelparam.labelNameList.forEach((s, index) => {
                      if (s.labelName == el.labelName) {
                        item.labelparam.labelNameList.splice(index, 1);
                      }
                    });
                  }
                });

                // 并取消已勾选的二级
                let namelistchild = item.labelparam2.filter((s) =>
                  item.codechildlist1.some((ele) => ele.labelName == s)
                );

                item.labelparam2 = namelistchild;
              } else if (index == 2) {
                this.selectedlist.forEach((h) => {
                  if (h.id == item.id) {
                    let labelname = h.tags.filter(
                      (s) =>
                        !this.childResults.some(
                          (ele) => ele.labelName == s.labelName
                        )
                    );
                    h.tags = labelname;
                  }
                });
                let namelabellist = item.childlist.filter((s) =>
                  this.childResults.some((ele) => ele.labelName == s.labelName)
                );
                if (this.childResults.length > 0) {
                  namelabellist.forEach((el) => {
                    // //取消勾选去除四级
                    if (el.checked) {
                      el.checked = !el.checked;
                      this.getChild(el.id, el, 3);
                    }
                  });
                  //取消勾选去除三级
                  let namelist = item.childlist.filter(
                    (s) =>
                      !this.childResults.some(
                        (ele) => ele.labelName == s.labelName
                      )
                  );

                  item.childlist = namelist;

                  namelabellist.forEach((el) => {
                    //清楚请求参数里的已勾选子集
                    if (el.groupLabelType == item.labelparam.labelType) {
                      item.labelparam.labelNameList.forEach((s, index) => {
                        if (s.labelName == el.labelName) {
                          item.labelparam.labelNameList.splice(index, 1);
                        }
                      });
                    }
                  });
                }

                //并去除已经勾选的三级
                let namelistchild = item.labelparam3.filter((s) =>
                  item.childlist.some((ele) => ele.labelName == s)
                );
                item.labelparam3 = namelistchild;
              } else if (index == 3) {
                this.selectedlist.forEach((h) => {
                  if (h.id == item.id) {
                    let labelname = h.tags.filter(
                      (s) =>
                        !this.childResults.some(
                          (ele) => ele.labelName == s.labelName
                        )
                    );
                    h.tags = labelname;
                  }
                });
                let namelabellist = item.childlist4.filter((s) =>
                  this.childResults.some((ele) => ele.labelName == s.labelName)
                );
                if (this.childResults.length > 0) {
                  namelabellist.forEach((el) => {
                    // //取消勾选去除五级
                    if (el.checked) {
                      el.checked = !el.checked;
                      this.getChild(el.id, el, 4);
                    }
                  });
                }

                let namelist = item.childlist4.filter(
                  (s) =>
                    !this.childResults.some(
                      (ele) => ele.labelName == s.labelName
                    )
                );

                item.childlist4 = namelist;

                namelabellist.forEach((el) => {
                  //清楚请求参数里的已勾选子集
                  if (el.groupLabelType == item.labelparam.labelType) {
                    item.labelparam.labelNameList.forEach((s, index) => {
                      if (s.labelName == el.labelName) {
                        item.labelparam.labelNameList.splice(index, 1);
                      }
                    });
                  }
                });
                //并去除已经勾选的四级
                let namelistchild = item.labelparam4.filter((s) =>
                  item.childlist4.some((ele) => ele.labelName == s)
                );
                item.labelparam4 = namelistchild;
              } else if (index == 4) {
                this.selectedlist.forEach((h) => {
                  if (h.id == item.id) {
                    let labelname = h.tags.filter(
                      (s) =>
                        !this.childResults.some(
                          (ele) => ele.labelName == s.labelName
                        )
                    );
                    h.tags = labelname;
                  }
                });
                let namelabellist = item.childcode5.filter((s) =>
                  this.childResults.some((ele) => ele.labelName == s.labelName)
                );
                if (this.childResults.length > 0) {
                  namelabellist.forEach((el) => {
                    // //取消勾选去除五级
                    if (el.checked) {
                      el.checked = !el.checked;
                      this.getChild(el.id, el, 5);
                    }
                  });
                }

                let namelist = item.childcode5.filter(
                  (s) =>
                    !this.childResults.some(
                      (ele) => ele.labelName == s.labelName
                    )
                );

                item.childcode5 = namelist;

                namelabellist.forEach((el) => {
                  //清楚请求参数里的已勾选子集
                  if (el.groupLabelType == item.labelparam.labelType) {
                    item.labelparam.labelNameList.forEach((s, index) => {
                      if (s.labelName == el.labelName) {
                        item.labelparam.labelNameList.splice(index, 1);
                      }
                    });
                  }
                });
                //并去除已经勾选的五级
                let namelistchild = item.labelparam5.filter((s) =>
                  item.childcode5.some((ele) => ele.labelName == s)
                );
                item.labelparam5 = namelistchild;
              } else if (index == 5) {
                this.selectedlist.forEach((h) => {
                  if (h.id == item.id) {
                    let labelname = h.tags.filter(
                      (s) =>
                        !this.childResults.some(
                          (ele) => ele.labelName == s.labelName
                        )
                    );
                    h.tags = labelname;
                  }
                });

                let namelist = item.childcode6.filter(
                  (s) =>
                    !this.childResults.some(
                      (ele) => ele.labelName == s.labelName
                    )
                );

                let namelabellist = item.childcode6.filter((s) =>
                  this.childResults.some((ele) => ele.labelName == s.labelName)
                );
                item.childcode6 = namelist;

                namelabellist.forEach((el) => {
                  //清楚请求参数里的已勾选子集
                  if (el.groupLabelType == item.labelparam.labelType) {
                    item.labelparam.labelNameList.forEach((s, index) => {
                      if (s.labelName == el.labelName) {
                        item.labelparam.labelNameList.splice(index, 1);
                      }
                    });
                  }
                });
                //并去除已经勾选的六级
                let namelistchild = item.labelparam6.filter((s) =>
                  item.childcode6.some((ele) => ele.labelName == s)
                );
                item.labelparam6 = namelistchild;
              }
            }
          }
        });
      }
      this.labelmatic();
    },
    // 判断右侧是否有值自动打开
    labelmatic() {
      this.activeNames = [];
      this.selectedlist.forEach((o) => {
        if (o.tags.length > 0) {
          this.activeNames.push(o.id);
        }
      });
    },
    codechange1(o) {
      //获取勾选的值保存
      if (!o.checked) {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            //根据标签类别相同进行添加
            item.labelparam.labelNameList.push({
              labelId: o.id,
              labelName: o.labelName,
            });
          }
        });
      } else {
        //取消勾选就根据类别删除labelparam里相应的值
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == o.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }
      //取反
      o.checked = !o.checked;
      //查询父级下的子标签
      this.getChild(o.id, o, 1);
      //右边回显
      if (o.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.push(o);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == o.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    codechangechild(i) {
      if (!i.checked) {
        this.radiolist.forEach((item) => {
          if (i.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.push({
              labelId: i.id,
              labelName: i.labelName,
            });
          }
        });
      } else {
        this.radiolist.forEach((item) => {
          if (i.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == i.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }
      i.checked = !i.checked;
      this.getChild(i.id, i, 2);
      //左边回显
      if (i.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == i.groupLabelType) {
            p.tags.push(i);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == i.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == i.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    //出现四级
    codechangechil4(o) {
      if (!o.checked) {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.push({
              labelId: o.id,
              labelName: o.labelName,
            });
          }
        });
      } else {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == o.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }

      o.checked = !o.checked;
      this.getChild(o.id, o, 3);
      //左边回显
      if (o.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.push(o);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == o.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    //出现五·级
    codechangechil5(o) {
      if (!o.checked) {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.push({
              labelId: o.id,
              labelName: o.labelName,
            });
          }
        });
      } else {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == o.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }
      o.checked = !o.checked;
      this.getChild(o.id, o, 4);
      //左边回显
      if (o.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.push(o);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == o.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    //出现六级
    paramchange5(o) {
      if (!o.checked) {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.push({
              labelId: o.id,
              labelName: o.labelName,
            });
          }
        });
      } else {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == o.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }
      o.checked = !o.checked;
      this.getChild(o.id, o, 5);
      //左边回显
      if (o.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.push(o);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == o.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    paramchange6(o) {
      if (!o.checked) {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.push({
              labelId: o.id,
              labelName: o.labelName,
            });
          }
        });
      } else {
        this.radiolist.forEach((item) => {
          if (o.groupLabelType == item.labelparam.labelType) {
            item.labelparam.labelNameList.forEach((s, index) => {
              if (s.labelName == o.labelName) {
                item.labelparam.labelNameList.splice(index, 1);
              }
            });
          }
        });
      }
      o.checked = !o.checked;
      //左边回显
      if (o.checked) {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.push(o);
          }
        });
      } else {
        this.selectedlist.forEach((p) => {
          if (p.id == o.groupLabelType) {
            p.tags.forEach((el, index) => {
              if (el.labelName == o.labelName) {
                p.tags.splice(index, 1);
              }
            });
          }
        });
      }
    },
    //去重
    removal(arr) {
      let newArr = [];
      let obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i].id]) {
          newArr.push(arr[i]);
          obj[arr[i].id] = true;
        }
      }
      return newArr;
    },
    cancls() {
      this.$router.go(-1);
    },

    //保存标签
    // async submit() {
    //   let query = [];
    //   this.radiolist.forEach((el) => {
    //     if (el.labelparam.labelNameList.length > 0) {
    //       query.push(el.labelparam);
    //     }
    //   });

    //   if (query.length > 0) {
    //     if (this.labeList.length == 0) {
    //       savelabel(query).then((response) => {
    //         if (response.data.resultCode == 200) {
    //           this.$message.success("保存成功");
    //           this.$router.go(-1);
    //         } else {
    //           this.$message.error(response.data.message);
    //         }
    //       });
    //     } else {
    //       updateInformationLabel(query).then((response) => {
    //         if (response.data.resultCode == 200) {
    //           this.$message.success("保存成功");
    //           this.$router.go(-1);
    //         } else {
    //           this.$message.error(response.data.message);
    //         }
    //       });
    //     }
    //   } else {
    //     this.$message.warning("请勾选标签！");
    //   }
    // },

    // 查询所有标签组
    async selectAllLabelGroup() {
      const res = await selectAllLabelGroup();
      if (res.code == 200) {
        this.allLabelGroup = res.data;
        this.$emit('clickload',true)
      }
    },

    // 模糊查询标签组
    async selectLikeGroupLabelName(val) {
      const res = await selectLikeGroupLabelName({
        groupLabelName: val,
      });
      if (res.code == 200) {
        this.allLabelGroup = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-tag {
  background-color: #ecf3ff;
  border-color: #dae8ff;
  color: #448aff;
  display: inline-block;
  height: 26px;
  padding: 0 10px;
  line-height: 26px;
  font-size: 12px;
  color: #448aff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 10px;
}
::v-deep .el-checkbox-group {
  font-size: 0;
  // width: 95%;
  width: 100%;
}
::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}
::v-deep .el-radio {
  padding: 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 256px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
.addenterprise {
  // padding: 10px 0px 10px 35px;
  .form_essential {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .form_essential_left {
      overflow: auto;
      width: 80%;
      padding: 0px 0px 0px 40px;
      box-sizing: border-box;
      /deep/.el-form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .el-form-item__content {
          margin-left: 0px !important;
        }
        /deep/.el-form-item__label {
          font-family: PingFang SC !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          // line-height: 22.4px;
          width: 100% !important;
          display: flex;
        }
      }
    }
    .form_essential_right {
      // padding: 20px 20px 20px 20px;
      // border: 1px solid #ccc;
      border-radius: 6px;
      width: 20%;
      box-sizing: border-box;
      // 需要滚动
      //  height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
      height: calc(100vh - 36px - 210px);

      // overflow: scroll;
      // border-top: 16px solid #f5f6f7;
      // border-left: 16px solid #f5f6f7;
      .tags {
        padding: 10px;
        background: #f5f6f7;
        height: 180px;
        overflow: auto;
      }
    }
  }
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .store_table {
    padding: 20px 0px;
    width: 100%;
    overflow: hidden;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;

    .store_table_item {
      padding: 0px 20px;
      overflow: hidden;
      line-height: 48px;
      box-sizing: border-box;
      color: #1f2d3d;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
    }

    .active {
      border-bottom: 2px solid #4e93fb;
      color: #4e93fb;
      background-color: #fff;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    display: flex;
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .form_essential_search {
    // 需要滚动
    // height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
    height: calc(100vh - 36px - 210px);
    margin-bottom: 10px;
    width: 20%;
    border-radius: 8px;
    box-sizing: border-box;
    // border: 1px solid #ccc;
    // padding: 10px;
    // overflow: scroll;
    .title {
      color: #146aff;
      font-family: PingFangSC-Regular, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      padding: 10px;
      box-sizing: border-box;
    }
    .search_input {
      /deep/.el-input__inner {
        width: 100%;
        height: 36px;
        border-radius: 4px;
      }
      /deep/.el-icon-search:before {
        font-size: 20px;
      }
      /deep/.el-form-item {
        margin-bottom: 10px;
      }
      /deep/.el-input__suffix {
        right: 6px;
      }
    }
    .list {
      padding: 0 10px;
      box-sizing: border-box;
      ul li {
        font-family: PingFangSC-Regular, sans-serif;
        font-weight: 500;
        line-height: 38px;
        text-align: left;
        font-size: 14px;
        color: #606266;
        span {
          cursor: pointer;
        }
      }
    }
  }
}
h4 {
  margin-bottom: 14px;
}

// .form_essential_right {
//   // height: calc(100vh - 312px) !important;
//   height: calc(100vh - 440px) !important;
//   overflow: auto !important;
// }
// .fixed {
//   // 需要滚动
//   height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
//   // height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px - 70px);

//   overflow: scroll;
//   margin-bottom: 10px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   padding: 10px;
//   box-sizing: border-box;
//   position: fixed;
// }
// .fixedRight {
//   padding: 20px 20px 20px 20px;
//   border: 1px solid #ccc;
//   border-radius: 6px;
//   box-sizing: border-box;
//   // 需要滚动
//   height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
//   // height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px - 70px);
//   overflow: scroll;
//   position: fixed;
//   right: 40px;
// }

.fixed {
  // height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
  height: calc(100vh - 36px - 210px);
  overflow: scroll;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
}
.fixedRight {
  width: 240px;
  padding: 20px 20px 20px 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  // height: calc(100vh - 56px - 10px - 20px - 40px - 20px - 20px);
  height: calc(100vh - 36px - 210px);
  overflow: scroll;
  position: fixed;
  right: 40px;
}
</style>
